(function($, _, aura, undefined) {
    "use strict";

    aura.DOM = aura.DOM || {};
    aura.DOM = $.extend(true, {}, aura.DOM || {});

    aura.DOM.body = $('body');
    aura.DOM.html = $('html');
    aura.DOM.window = $(window);
    aura.DOM.document = $(document);

    function setupShims() {
        if (!Array.prototype.indexOf) {
            Array.prototype.indexOf = function (needle) {
                return _.indexOf(this, needle);
            };
        }

        if (!String.prototype.startsWith) {
            String.prototype.startsWith = function (str) {
                return this.lastIndexOf(str, 0) === 0;
            };
        }

        if (!String.prototype.trim) {
            String.prototype.trim = function () {
                return this.replace(/^\s+|\s+$/g, '');
            };
        }
    }
    
	function setupHighlightPlugin() {
	    $.fn.highlight = function (color) {
	        return this.each(function() {
	            var element = $(this);
                $("<div/>")
	                .width(element.outerWidth())
	                .height(element.outerHeight())
	                .css({
	                    "position": "absolute",
	                    "left": element.offset().left,
	                    "top": element.offset().top,
	                    "background-color": (color !== undefined) ? color : "#ffff99",
	                    "opacity": "0.7",
	                    "z-index": "9999999",
	                    "border-top-left-radius": parseInt(element.css("borderTopLeftRadius"), 10),
	                    "border-top-right-radius": parseInt(element.css("borderTopRightRadius"), 10),
	                    "border-bottom-left-radius": parseInt(element.css("borderBottomLeftRadius"), 10),
	                    "border-bottom-right-radius": parseInt(element.css("borderBottomRightRadius"), 10)
	                })
	                .appendTo('body')
	                .fadeOut(1000)
	                .queue(function() {
	                    $(this).remove();
	                });
	        });
	    };
	}

	function setupAccordionPlugin() {
	    var pluginName = 'accordionme',
	        defaults = {
	            propertyName: "value"
	        };

	    function Plugin(element, options) {
	    	var self = this;

	    	self.element = element;
	    	self.options = $.extend({}, defaults, options);
	    	self._defaults = defaults;
	    	self._name = pluginName;
	    	self.init();

	        return self;
	    }

		Plugin.prototype.init = function () {
		    $(".active-list > ul", this.element).show();
			$(".has-child", this.element).on("click", function (e) {
				e.preventDefault();
				$("li", this.element).not(".active-list").find("ul").stop().slideUp();
				$(this).siblings("ul").stop().slideDown();
			});
		};

	    $.fn[pluginName] = function(options) {
	        return this.each(function() {
	            if (!$.data(this, 'plugin_' + pluginName)) {
	                $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
	            }
	        });
	    };
	}

	function setupNumberFormatMoney() {
	    if (!Number.prototype.formatMoney) {
	        Number.prototype.formatMoney = function(decimalPlaces, commaSep, thousandSep) {
                decimalPlaces = isNaN(decimalPlaces = Math.abs(decimalPlaces)) ? 2 : decimalPlaces;
                commaSep = commaSep == undefined ? "." : commaSep;
                thousandSep = thousandSep == undefined ? "," : thousandSep;
	            var n = this,
	                s = n < 0 ? "-" : "",
	                i = parseInt(n = Math.abs(+n || 0).toFixed(decimalPlaces)) + "",
	                j = (j = i.length) > 3 ? j % 3 : 0;
	            return s + (j ? i.substr(0, j) + thousandSep : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandSep) + (decimalPlaces ? commaSep + Math.abs(n - i).toFixed(decimalPlaces).slice(2) : "");
	        };
	    }
	}


	setupShims();
	setupHighlightPlugin();
	setupAccordionPlugin();
	setupNumberFormatMoney();

    /**
    * Object to contain scoped utilities / functions
    */
	aura.functions = aura.functions || {};
	aura.functions = $.extend(true, {}, aura.functions || {});

	aura.functions.createModalBare = function(modalId, modalClass, modalInnerContentId, modalInnerContent, realModal) {
        
        var existingModal = $('#' + modalId);
        if (existingModal.length) {
            existingModal.modal('hide');
            existingModal.remove();
        }

        // Outmost modal
        var modal = $('<div></div>', {
            'id': modalId,
            'class': 'modal',
            'tabindex': -1,
            'role': 'dialog',
            'aria-labelledby': modalId,
            'aria-hidden': false
        });

        // Outer dialog
        var modalDialog = $('<div></div>', {
            'class': 'modal-dialog ' + modalClass
        });
        modal.append(modalDialog);

        // Outer content
        var modalContent = $('<div></div>', {
            'class': 'modal-content',
            'id': modalInnerContentId
        });
        modalDialog.append(modalContent);

        if (modalInnerContent) {
            modalContent.append(modalInnerContent);
        }

        if (realModal) {
            modal.attr('data-keyboard', 'false');
            modal.attr('data-backdrop', 'static');
        }

        aura.DOM.body.append(modal);

	    // Make the modal disappear when closed.
        modal.on('hidden.bs.modal', function () {
            $(this).remove();
        });

        return modal;
    };

    /**
     * Helper to create a Twitter Bootstrap modal. It appends itself to body, but has to be activated manually.
     * @param {String} modalId The ID of the modal.
     * @param {String} modalClass The CSS class for styling the modal.
     * @param {String} headerText The text (not HTML) for the header.
     * @param {String} bodyHtml The body HTML / text.
     * @param {String} footerHtml The footer HTML / text.
     * @param {Boolean} realModal True/false to make this modal 'real' or not; disables backdrop click and keyboard.
     * @returns {DOM} Returns the created modal dialog
     */
	aura.functions.createModal = function (modalId, modalClass, headerText, bodyHtml, footerHtml, realModal) {
	    var existingModal = $('#' + modalId);
        if (existingModal.length) {
            existingModal.modal('hide');
            existingModal.remove();
        }
        
        // Outmost modal
        var modal = $('<div></div>', {
            'id': modalId,
            'class': 'modal',
            'tabindex': -1,
            'role': 'dialog',
            'aria-labelledby': modalId,
            'aria-hidden': false
        });

        // Outer dialog
        var modalDialog = $('<div></div>', {
            'class': 'modal-dialog ' + modalClass
        });
        modal.append(modalDialog);

        // Outer content
        var modalContent = $('<div></div>', {
            'class': 'modal-content'
        });
        modalDialog.append(modalContent);

        if (headerText) {
            // Header
            var modalHeader = $('<div></div>', {
                'class': 'modal-header'
            });
            if (!realModal) {
                modalHeader.append($('<button></button>', {
                    'type': 'button',
                    'class': 'close',
                    'data-dismiss': 'modal',
                    'aria-label': 'Close'
                }));
            }
            modalHeader.append($('<h4></h4>', {
                'class': 'modal-title white-text',
                'style': 'margin:0;'
            }).text(headerText));
            modalContent.append(modalHeader);
        }

        if (bodyHtml) {
            // Body
            var modalBody = $('<div></div>', {
                'class': 'modal-body'
            }).html(bodyHtml);
            modalContent.append(modalBody);
        }

        if (footerHtml) {
            // Footer
            var modalFooter = $('<div></div>', {
                'class': 'modal-footer'
            }).html(footerHtml);
            modalContent.append(modalFooter);
        }

        if (realModal) {
            modal.attr('data-keyboard', 'false');
            modal.attr('data-backdrop', 'static');
        }

        aura.DOM.body.append(modal);

        // Make the modal disappear when closed.
        modal.on('hidden.bs.modal', function() {
            window.console.log("hide dynamic model");
            $(this).remove();
        });

        return modal;
	};

    aura.functions.createLoaderModal = function() {
        return aura.functions.createModal("pleaseWaitModal", "pleaseWaitModal", 'Indlæser', "Vent venligst", null, true);
    };

}(window.jQuery, _, (window.AURA = window.AURA || {})));