/*global jQuery, _ */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: ...
 Date of creation: ...
 */

var dis = dis || {};

(function ($, dis, underscore) {
    "use strict";


    /**
     * The current width of the window, accessible in your constructor through this.currentWindowWidth
     * See usage example inside dis.module.example.js
     *
     * @type {number}
     */
    dis.BaseModule.prototype.currentWindowWidth = 0;


    /**
     * The current height of the window, accessible in your constructor through this.currentWindowHeight
     * See usage example inside dis.module.example.js
     *
     * @type {number}
     */
    dis.BaseModule.prototype.currentWindowHeight = 0;


    /**
     * An array of screen sizes, in order to easily being able to identify the current screen size as defined
     * by the active media query.
     * See usage example inside dis.module.example.js
     *
     * @type {{unknown: number, mobile: number, largeMobile: number, tablet: number, desktop: number, largeDesktop: number, veryLargeDesktop: number}}
     */
    dis.BaseModule.prototype.screenSizes = {
        unknown: 0,
        xxs: 1,	// phone (default before 'wide phone' breakpoint)
        xs: 2,	// wide phone (480px by default)
        sm: 3,	// iPad portrait (768px)
        md: 4,	// iPad landscape (1024px)
        lg: 5,	// HD720 (1280px)
        xl: 6,	// large laptop (1600px by default)
        xxl: 7	// HD1080 (1920px)
    };


    /**
     * The current screen mode. This will correspond to one of the predefined screen sizes.
     * See usage example inside dis.module.example.js
     *
     * @type {number}
     */
    dis.BaseModule.prototype.currentScreenMode = dis.BaseModule.prototype.screenSizes[1];


    /**
     * Using the IIFE, "onWindowResize" becomes a function that takes a single argument. You can use it from within
     * your own constructor like so:
     *
     *     this.onWindowResize(function () { });
     *
     * This will fire your function every time the window is resized, or the window orientation changes. Neato!
     *
     * @type {function}
     */
    dis.BaseModule.prototype.onWindowResize = (function () {

        var functionQueue = [],
            underscoreThrottle,
            mqIndicator,
            screenSizeCount = underscore.size(dis.BaseModule.prototype.screenSizes),
            returnedFunction;


        /**
         * Get various window sizes - width, height etc. - and store them on our prototype.
         */
        function getWindowSizes() {
            // Get the width of our media query indicator. This will return the width as defined in main.scss!
            var currentMQIndicatorWidth = mqIndicator.width();

            dis.BaseModule.prototype.currentWindowWidth = dis.BaseModule.prototype.globalDOM.window.width();
            dis.BaseModule.prototype.currentWindowHeight = dis.BaseModule.prototype.globalDOM.window.height();

            // Store the screen mode, if it's inside our screen size array (defined above). This enables you to match the
            // current screen size with an actual media query, instead of handling the checks in your JS.
            dis.BaseModule.prototype.currentScreenMode = currentMQIndicatorWidth < screenSizeCount ? currentMQIndicatorWidth : 0;
        }


        /**
         * This function is run every time the window is resized. It's on a throttle, though, so it won't be fired too
         * often.
         */
        function performCalculationCallback() {
            // Get our current window size(s)
            getWindowSizes();

            // Fire all queued functions that other scripts may have registered.
            underscore.each(functionQueue, function (funcRef) {
                funcRef();
            });
        }

        // Set up a throttle, so our callback function is fired a maximum of once per second.
        underscoreThrottle = underscore.throttle(performCalculationCallback, 1000);

        // Utilize our prototyped "ready"-function to bind an event for handling window resizes and orientation changes.
        dis.BaseModule.prototype.onReady(function () {
            dis.BaseModule.prototype.globalDOM.window.bind("resize orientationchange", underscoreThrottle);

            // Create a media query indicator that we can use to check the current screen size, compared to our media queries.
            mqIndicator = $("<div></div>", {id: "mediaQueryIndicator"});
            mqIndicator.css("display", "none");
            dis.BaseModule.prototype.globalDOM.body.append(mqIndicator);

            // Get our current window size, because we might need the data.
            getWindowSizes();
        });


        /**
         * Store a function reference to be fired every time the window is resized.
         *
         * @param {function} functionReference
         */
        returnedFunction = function (functionReference) {
            if (typeof functionReference === "function") {
                functionQueue.push(functionReference);
            }
        };

        // Return a function that can store an incoming function reference and fire it once the window is resized.
        return returnedFunction;

    }());


}(jQuery, dis, _));