(function($, _, aura, window, document, undefined) {
    "use strict";


    if (!$) {
        throw "Unable to load jQuery - is it referenced?";
    }


    if (!_) {
        throw "Unable to load underscore.js - is it referenced?";
    }
    

    // Local variables
    var setBlocksHeightTimer;
    var setIframeDimensionsTimer;
    var isOldIE = (navigator.userAgent.indexOf("MSIE") !== -1); // Detect IE10 and below
    var pleaseWaitModalId = '#pleaseWaitModal';
    var surveyCookieName = 'auraSurveyOpened';
    var dawaAddressID;
    var emptyUUID = "00000000-0000-0000-0000-000000000000";
    var isTouch =  !!("ontouchstart" in window) || window.navigator.msMaxTouchPoints > 0;

    if( !isTouch ){
        $('html').addClass('no-touch');
    }

    aura.DOM.htmlAndBody = $('html,body');


    /**
    * Main init function
    */
    aura.init = function() {
        // moment.js setup
        moment.locale("da");

        aura.events.init();

        aura.functions.initCookieConsent();
        
        aura.functions.handleIframeAutoResize();
        aura.functions.setupIframesObserver(aura.functions.handleIframeAutoResize);

        aura.functions.setIframeDimensions($('body .container iframe'));
        aura.functions.handleSurveyModal();
        aura.functions.setupPrettyAccordion();
        aura.functions.setupCo2();
        aura.functions.toggleItem();
        aura.validation.init();
        aura.functions.responsiveTables();
        aura.functions.handleJqueryPopup();

        svg4everybody();

        //When clicking js-recalculate-product-header-height and showing/hiding product tags - recalculate product header height
        $('.js-recalculate-product-header-height').click(function(){
            setBlocksHeightTimer = setTimeout(function () {
                aura.functions.setBlockHeights('.js-product-grid__header-inner');
            }, 100);
        });

        aura.functions.initTooltips();

        //on some pages we have items that should have focus on load
        if($('[data-focus-on-load]').length){
            aura.DOM.focusInputOnLoad = $('[data-focus-on-load]');
            aura.DOM.focusInputOnLoad.focus();
        }


        if($('#fullwidth-search__input').length ) {
            aura.functions.initAddressLookup();
        }

        if($('#modal--gdpr').length ) {
            window.console.log("GDPR modal exists");
            aura.events.functions.showGdprModal();
        }

        if($('#fiberFlowForm').length ) {
            aura.events.functions.initFiberFlow();
        }

        $( window ).resize(function() {
            $('.ui-autocomplete').hide();
        });

        if($('.custom-form .js-dawa-autocomplete').length ) {
            $('.custom-form .js-dawa-autocomplete').dawaautocomplete({
                select: function (event, address) {
                    if($('input#dawaAddressId')) {
                        $('input#dawaAddressId').val(address.data.id);
                    }

                    if($('input#dawaAddress')) {
                        $('input#dawaAddress').val(address.tekst);
                    }
                }
            });
        }

        // MVC date validator for DK dates 
        $.validator.methods.date = function (value, element) {
            return this.optional(element) || moment(value, "DD-MM-YYYY hh:mm:ss") !== null;
        };
        document.addEventListener('close-modal', function () {
            $(pleaseWaitModalId).modal('hide');
        });
    };


    /**
    * Delayed main init function
    */
    aura.delayedInit = function() {
        aura.events.delayedInit();

        setBlocksHeightTimer = setTimeout(function () {
            aura.functions.setBlockHeights('.js-product-grid__header-inner');
        }, 200);

        setBlocksHeightTimer = setTimeout(function () {
            aura.functions.setBlockHeights('.js-product-grid__content-inner');
        }, 200);

        aura.functions.setBlockHeights('.row.row-eq-height [class*="col-"]');
        aura.functions.setBlockHeights('.rep-person__info');

        if($('#permission-txt-area').length){
            aura.events.functions.setupPermissionEvents();
        }

        aura.events.functions.ExcavationDeeplink();
        if(aura.functions.detectIE()){
            $('html').addClass('ie');
        }

        if($('.image-explanation [data-image-explanation-pin]').length){
            var explanationPinArray = $('.image-explanation [data-image-explanation-pin]');

            explanationPinArray.each(function(){
                var explanationPin = $(this),
                    explanationPinNum = explanationPin.attr('data-image-explanation-pin'),
                    explanationImg = explanationPin.parents(".image-explanation"),
                    explanationInfoContainer = explanationImg.next(),
                    explanationInfo = explanationInfoContainer.find("[data-explanation-info='" + explanationPinNum + "']");

                explanationPin.on({
                    //focus event not working on IOS
                    click: function () {
                        //repositioning the info each time before showing it
                        aura.functions.positionExplanationInfo(explanationImg,explanationPin,explanationInfo);
                        explanationInfo.addClass('active');
                    },
                    blur: function () {
                        explanationInfo.removeClass('active');
                    }
                });
            });

            //links inside info handler
            //we use mousedown since this event occurs before blur
            $('.image-explanation__info a').on("mousedown", function(e){
                e.stopPropagation();
                e.preventDefault();
            });

            $('.image-explanation__close').click(function(){
                $(this).parents('.image-explanation__info').removeClass('active');
            });
        }
    };


    /**
    * Object to contain scoped utilities / functions
    */
    aura.functions = aura.functions || {};
    aura.functions = $.extend(true, {}, aura.functions || {});


    aura.functions.initAddressLookup = function(){
        var $form = $('.dawa-search--address');
        dawaAddressID = $('#dawaAddressId').val();

        //when address is not changed we disable form submit
        //this prevents form form submitting before dawa address is set which was causing and error when clicking fanatically in field :-)
        if($form.hasClass('dawa-search--fiber') || $form.hasClass('dawa-search--fiberpulje')) {
            $form.submit(function () {
                return false;
            });
        }

        if(dawaAddressID === emptyUUID){
            $('.fullwidth-search__clear-btn').hide();
        }

        $('#fullwidth-search__input').dawaautocomplete({
            select: function (event, address) {
                $('.fullwidth-search')
                    .removeClass('fullwidth-search--noAddressSelected')
                    .removeClass('fullwidth-search--addressSelected')
                    .addClass('fullwidth-search--searching');

                if($('input[name="addressFullName"]')){
                    $('input[name="addressFullName"]').val(address.tekst);
                }

                if($('input[name="dawaAddressId"]')){
                    $('input[name="dawaAddressId"]').val(address.data.id);
                }

                dawaAddressID = address.data.id;

                //instead of ajax we need a page redirect
                if($form.hasClass('dawa-search--fiber')){
                    $.ajax({
                        type: $form.attr("method"),
                        url: $form.attr("action"),
                        dataType: 'html',
                        data: $form.serialize(),
                        success: function(response) {
                            $form.parent()
                                .replaceWith(function() {
                                    return $(response).hide().fadeIn();
                                });

                            //update css clases and re-init new html
                            $('.fullwidth-search')
                                .addClass('fullwidth-search--addressSelected')
                                .removeClass('fullwidth-search--noAddressSelected');

                            aura.functions.initAddressLookup();
                        },
                        error: function(){
                            $('.fullwidth-search')
                                .removeClass('fullwidth-search--addressSelected')
                                .addClass('fullwidth-search--noAddressSelected');
                        }
                    });
                } else if($form.hasClass('dawa-search--fiberpulje')) {
                    var fiberpuljeForm = $('#fiber-pulje');
                    var responseWrapper = $('.fullwidth-search__message');
                    fiberpuljeForm.fadeOut();
                    $('#fullwidth-search__input').blur();

                    $.ajax({
                        type: $form.attr("method"),
                        url: $form.attr("action"),
                        dataType: 'json',
                        data: $form.serialize(),
                        success: function(response) {

                            //update css clases
                            $('.fullwidth-search')
                                .removeClass('fullwidth-search--searching')
                                .addClass('fullwidth-search--addressSelected')
                                .removeClass('fullwidth-search--noAddressSelected');

                            if(response.canSignup) {
                                fiberpuljeForm.fadeIn();
                                fiberpuljeForm.find('#fiberpuljeName').focus();
                            }

                            //display message from backend
                            responseWrapper.html(response.message).show();
                        },
                        error: function(){
                            $('.fullwidth-search')
                                .removeClass('fullwidth-search--addressSelected')
                                .addClass('fullwidth-search--noAddressSelected');
                        }
                    });
                } else {
                    $form.submit();
                }
            },
            error: function(xhr, status, error) {
                //
            }
        });

        $('.fullwidth-search__clear-btn').on('click', function(){
            var $this = $(this);
            $this.hide();
            aura.functions.clearDAWAAddress();
            $this.parent().find('input[type=text]').focus();
        });

        // Reset dawa address search lookup
        $('#js-reset-dawa-address').on('click', function() {
            aura.functions.clearDAWAAddress();
            $('#js-loader-when-resetting-dawa-address').removeClass('u-visuallyhidden');
            $('#dawa-address-search-form')[0].submit();
        });

        $('#fullwidth-search__input').on('blur', function(){
            var $this = $(this),
                $clearBtn = $(".fullwidth-search__clear-btn");

            if(dawaAddressID === emptyUUID){

                $('.fullwidth-search')
                    .removeClass('fullwidth-search--addressSelected')
                    .addClass('fullwidth-search--noAddressSelected');
            } else {
                $('.fullwidth-search')
                    .removeClass('fullwidth-search--noAddressSelected')
                    .addClass('fullwidth-search--addressSelected');
            }

            //if there is a value in the input field we would like to display an erase button that if triggered calls the function aura.functions.clearDAWAAddress()
            if ($this.val() !== "") {
                $clearBtn.show();
            } else {
                $clearBtn.hide();
            }
        });

        $("#dawa-search--address .btn--triangle-shadow").on('click', function(){
            $("#dawa-search--address").submit();
        });

        $("#dawa-search--address").on('submit', function(){
            e.preventDefault();
            $('.fullwidth-search').removeClass('fullwidth-search--searching');
            $('.dawa-search--js-helptext').show();
        });
    };

    /* viewport width
     * https://andylangton.co.uk/blog/development/get-viewportwindow-size-width-and-height-javascript
     * */
    aura.functions.viewportWidth = function() {
        var e = window, a = 'inner';
        if (!('innerWidth' in window )) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return e[ a+'Width' ];
    };

    /*
    * Function that handles positioning of explanation info overlays on images containing explanation pins
    * The function is also used on window resize event
    */
    aura.functions.positionExplanationInfo = function(img,pinElement,infoElement){
        var imageHeight = img.height(),
            pinYpos = pinElement.position().top,
            infoYpos = Math.round(imageHeight - pinYpos);

        infoElement.css('top', -infoYpos);
    };

    aura.functions.onClickOutside = function(element, callback) {
        var clickElement = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : window;

        if (typeof callback === "function") {

            // Define a placeholder for the removeEventHandler so we can use it in the eventHandler-function below.
            var removeEventHandler = void 0;

            var eventHandler = function eventHandler(e) {
                // If clicked element doesn't match the main element or any of its children
                if (!$(e.target).closest(element).length) {
                    // Execute callback function and remove event listener
                    removeEventHandler();
                    callback();
                }
            };

            // Set up the actual removeEventHandler-function.
            removeEventHandler = function removeEventHandler() {
                return clickElement.removeEventListener("click", eventHandler);
            };

            // In case this function was called from within an event, set the new click handler inside
            // a timeout to allow the original event to finish propagating.
            setTimeout(function () {
                return clickElement.addEventListener("click", eventHandler);
            });
        }
    };

    //Since we cant change the markup on tables inside content-areas we use this little trick to get the responsive tables the right overflow on small screens
    aura.functions.responsiveTables = function(){
        $('.table-responsive').each(function(){
            $(this).removeClass('table-responsive').addClass('table table-bordered').wrap( "<div class='table-responsive'></div>" );
        });
    };

    aura.functions.setBlockHeights = function (blocks, exclude, mobile) {
        var lastPosition,
            supHeight = 0,
            temp = [];

        if (mobile === true) {
            if (window.outerWidth > 991) {
                mobile = false;
            }
        } else {
            mobile = false;
        }

        // If row has maybe a checkbox checked exclude element
        $(blocks).each(function (i, obj) {

            var excludeBool = false;
            if (exclude !== undefined) {
                if ($(this).find("" + exclude).length > 0) {
                    excludeBool = true;
                }
            }

            if (!excludeBool && !mobile) {
                var current = $(this).height('auto'),
                    position = current.offset().top,
                    height = current.outerHeight(true),
                    resize = false;

                // If this is a new row
                if (lastPosition && position > lastPosition) {
                    temp = [];
                    supHeight = 0;
                }


                // Push current element to temporary array
                temp.push(current);


                // Needs resizing?
                resize = (supHeight && height !== supHeight);

                // Remember data for next time
                lastPosition = position;
                supHeight = Math.max(supHeight, height);

                // Resize all blocks in this row
                if (resize) {
                    $.each(temp, function () {
                        $(this).outerHeight(supHeight);
                    });
                }
            }

            if (mobile) {
                $(this).height('auto');
                $(this).removeClass("--small-height");
            }
        });
    };
    aura.functions.setIframeDimensions = function (iframeSelector) {
        if (!iframeSelector.length || iframeSelector.data('autodimensions') === undefined || iframeSelector.data('autodimensions') !== true) {
            return;
        }

        var maxWidth = iframeSelector.parent().width(),
            aspectRatio = 16 / 9;

        iframeSelector.attr('width', maxWidth);
        iframeSelector.attr('height', Math.floor(maxWidth / aspectRatio));
    };
    aura.functions.handleIframeAutoResize = function () {

        var iframesToInit = $('body iframe[data-auto-resize="true"]:not([src=""])');

        iframesToInit.css('width', '100%').iFrameResize({
            checkOrigin: false,
            heightCalculationMethod: isOldIE ? 'max' : 'lowestElement',
            minSize: 100
        });
    };
    aura.functions.handleSurveyModal = function () {
        var surveyCookie = Cookies.get(surveyCookieName);
        if (surveyCookie === undefined) {
            $('#surveyModal').modal({ backdrop: 'static', keyboard: false });
        }
    };
    aura.functions.setupPrettyAccordion = function () {
        $(".accord-nav").accordionme();
        $(".pretty").each(function () {
            $(this).prettyCheckable();
        });
    };
    aura.functions.scroller = function (item) {
        aura.DOM.htmlAndBody.animate({
            scrollTop: item.offset().top
        }, 800);
    };
    aura.functions.preloadImages = function (arrayOfImages) {
        $(arrayOfImages).each(function () {
            $('<img/>')[0].src = this;
        });
    };
    aura.functions.setupCo2 = function () {
        $.each($('.calrow'), function () {
            var co2Elm = $(this).children('.co2');
            var priceElm = $(this).children('.price');
            var pricePerKvote = parseFloat($('.result-price').data('priceperkvote'));
            $(this).children('.cal-this').children('input').keyup(function () {
                var co2 = $(this).val() * $(this).data('co2per');
                var price = co2 * pricePerKvote;
                priceElm.html(price.toFixed(3));
                co2Elm.html(co2.toFixed(3));

                var cCo2 = 0;
                $.each($('.co2'), function () {
                    cCo2 += parseFloat($(this).html());
                });

                $('.result-co2').html(Math.ceil(cCo2) + ' CO<sub>2</sub> kvoter');
                $('.result-price').html(Math.ceil(cCo2) * pricePerKvote + ' kr');
            });
        });
    };

    aura.functions.toggleItem = function () {
        $(document).on('click', '.toggle', function (e) {
            e.preventDefault();

            $(this).toggleClass('active');

            //Rewrite this logic so any active childrens make sure
            if ($(this).hasClass('active') || $(this).find('a').hasClass('active')) {
                $(this).siblings('.accord-sub-nav').first().attr('aria-hidden', 'false');
            } else {
                $(this).siblings('.accord-sub-nav').first().attr('aria-hidden', 'true');
            }
        });
    };


    /*
    * Elements with trigger class 'js-popup-in-new-window' will open href in new popup window using the jquery.popup-window plugin.
    * The plugin is part of plugins.min.js via bower
    */
    aura.functions.handleJqueryPopup = function () {
        $('.js-popup-in-new-window').on('click', function (event) {
            event.preventDefault();

            var url = $(this).attr('href');

            $.popupWindow(url, {
                height: 500,
                width: 640,
                center: 'parent'
            });
        });
    };

    aura.functions.clearDAWAAddress = function () {
        $('#fullwidth-search__input').val('');

        dawaAddressID = emptyUUID;
        $('input[name="dawaAddressId"]').val(dawaAddressID);
    };

    aura.functions.detectIE = function () {
        var ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    };


    aura.functions.initCookieConsent = function () {

        function checkCookieInformationConsent() {

            function updateBlockedTexts(category) {

                if (CookieInformation.getConsentGivenFor(category)) {
                    var elements = document.querySelectorAll('[class="blockedIframeText ' + category + '"]');

                    for (var i = 0; i < elements.length; i++) {
                        elements[i].style.display = 'none';
                    }
                }
            }

            updateBlockedTexts('cookie_cat_marketing');
        }

        window.addEventListener('CookieInformationConsentGiven', checkCookieInformationConsent, false);

        checkCookieInformationConsent();
    };

    aura.functions.setupIframesObserver = function (onMutation) {

        function callback(mutationsList) {
            for (var i = 0; i < mutationsList.length; i++) {
                if (mutationsList[i].type === 'childList') {
                    onMutation();
                }
            }
        }

        var observer = new MutationObserver(callback);

        var iframes = document.querySelectorAll('.iframe-container');

        for (var i = 0; i < iframes.length; i++) {
            observer.observe(iframes[i], { attributes: false, childList: true, subtree: true });
        }
    };

    aura.functions.initTooltips = function() {        
        $('.tooltip').tooltipster({
            contentAsHTML: true,
            position:'top'
        });
    };

    /**
    * Events
    */
    aura.events = {
        init: function () {
            aura.events.functions.setupCustomSelect();
            aura.events.functions.setupDatePickers();

            $(window)
                .on('resize', _.throttle(aura.events.functions.variousResizeEvents, 200));

            $('body')
                .on('click', '#surveyModal #hideModal', aura.events.functions.surveyModalDismiss)
                .on('click', 'ul.nav-tabs > li > a[data-url]', aura.events.functions.navTabsClick)
                .on('click', '[data-type~="pleaseWait"]', aura.events.functions.pleaseWaitLinkClick)
                .on('click', '[data-type~="product"]', aura.events.functions.productLinkClick)
                .on('click', '[data-pricetrend-trigger]', aura.events.functions.priceTrendsClick)
                .on('click', '#selfServiceFormSubmitBtn', aura.events.functions.selfServiceFormSubmit)
                .on('click', '.scroll-down', aura.events.functions.scrollDownClick)
                .on('click', '.learn-more', aura.events.functions.learnMoreClick)
                .on('click', '.scrollTo', aura.events.functions.scrollToClick)
                .on('click', 'a[data-toggle="tab"]', aura.events.functions.tabToggleClick)
                .on('click', '.table--accordion [data-table--accordion-toggle]', aura.events.functions.toggleTableAccordion)
                .on('submit', 'form[data-excavation-notification="subscribe"]', aura.events.functions.excavationSubscribe)
                .on('submit', 'form[data-excavation-notification="unsubscribe"]', aura.events.functions.excavationUnSubscribe)
                .on('submit', '.gdpr-form form', aura.events.functions.gdprRequest)
                .on('submit', '#fiber-pulje', aura.events.functions.fiberPulje)
                .on('submit', '.power-product-block', aura.events.functions.beforeSubmitPowerProduct)
                .on('click', '#gdpr-accept', aura.events.functions.gdprAccept)
                .on('change', '.operation-table .slider-toggle__input', aura.events.functions.operationalInfoChange)
                .on('hide.bs.modal', '#operationalInfo', aura.events.functions.operationalInfoCancel)
                .on('mouseenter', '.navigation a', aura.events.functions.navigationLinkMouseEnter)
                .on('mouseleave', '.navigation a', aura.events.functions.navigationLinkMouseLeave)
                .on('mouseenter', '.greyscaleHolder a', aura.events.functions.greyscaleMouseEnter)
                .on('mouseleave', '.greyscaleHolder a', aura.events.functions.greyscaleMouseLeave)
                .on('change', 'input.toggle-required', aura.events.functions.toggleRequired)
                .on('change', '.selfservice-login-wrapper input[type="radio"][name="selfservice-login"]', aura.events.functions.selfServiceChangeNemID)
                .on('click', '.js-focus-address-search', aura.events.functions.focusAddressSearchAndMessage)
                .on('click', '.dropdown-info', aura.events.functions.infoModal)
                .on('click', 'a[href*="#"]', aura.events.functions.anchorScroll);

            $('html')
                .on('click', aura.events.functions.htmlClick);

            $(".rep-person__description-trigger").click(function () {
                var clicked = $(this),
                    target,
                    targetFromHeight = 0,
                    targetToHeight = 0;

                if (!clicked.hasClass("deactivated")) {
                    target = clicked.siblings(".rep-person__description-target");

                    if (!clicked.hasClass("active")) {
                        // Activate clicked
                        clicked.addClass("active");

                        // Remove max-height on transition-end
                        // so it still looks right if the window resizes
                        target.on("transitionend", function () {
                            target.off().css("max-height", "none");
                        });

                        // Get target's new height
                        targetToHeight = target.find("> div").outerHeight();
                    } else {
                        // Deactivate clicked
                        clicked.removeClass("active");

                        // Set the height to animate from (current height)
                        targetFromHeight = target.find("> div").outerHeight();
                        target.css("max-height", targetFromHeight);
                    }

                    // Force redraw and set new height
                    target.hide().show(0);
                    target.css("max-height", targetToHeight);
                }
            });
        },

        delayedInit: function () {
            aura.events.functions.bindBlockHeightEvent();
            aura.events.functions.bindIframeEvent();
        },

        functions: {
            toggleRequired: function () {
                // checkbox toggle property required on another input
                var $this = $(this);

                var targetId = $this.data('required-targetid');
                var target = $(targetId);

                if ($this.is(':checked')) {
                    target.prop('required', true);
                } else {
                    target.prop('required', false);
                    target.removeClass('input-validation-error error');
                }
            },
            bindBlockHeightEvent: function () {
                aura.DOM.window.resize(function () {
                    clearTimeout(setBlocksHeightTimer);
                    setBlocksHeightTimer = setTimeout(function () {
                        aura.functions.setBlockHeights('.js-product-grid__content-inner');
                        aura.functions.setBlockHeights('.js-product-grid__header-inner');
                        aura.functions.setBlockHeights('.row.row-eq-height [class*="col-"]');
                        aura.functions.setBlockHeights('.rep-person__info');
                    }, 200);
                });
            },
            bindIframeEvent: function () {
                aura.DOM.window.resize(function () {
                    clearTimeout(setIframeDimensionsTimer);
                    setIframeDimensionsTimer = setTimeout(function () {
                        aura.functions.setIframeDimensions($('body .container iframe'));
                    }, 200);
                });
            },
            //function is throttled with underscore
            variousResizeEvents: function () {
                aura.events.functions.handleIframeContentLoad();

                //set product blocks top equal height, to align order buttons
                aura.functions.setBlockHeights('.js-product-grid__header-inner');
            },
            handleIframeContentLoad: function () {
                var iframeInContent = $('body .container iframe'),
                    showModalBeforeIframe = iframeInContent.data('showModal');
                if (iframeInContent.length && showModalBeforeIframe !== undefined && showModalBeforeIframe === true && iframeInContent.attr('href') !== '' && iframeInContent.attr('href') !== undefined) {
                    window.console.log("handleIframeContentLoad");
                    $(pleaseWaitModalId).modal('show');
                    iframeInContent.load(function () {
                        $(pleaseWaitModalId).modal('hide');
                        $(document.body).removeClass("modal-open");
                        $(".modal-backdrop").remove();
                    });
                }
            },
            setupCustomSelect: function () {
                $('.customSelect').customSelect();
            },
            setupDatePickers: function () {
                //init http://eonasdan.github.io/bootstrap-datetimepicker/
                $(".use-datetimepicker input").datetimepicker({
                    format: 'DD-MM-YYYY HH:mm:ss'
                });

                $(".use-datepicker").datepicker({
                    format: 'DD-MM-YYYY'
                });

                if ($('#inputDate1').size() > 0) {
                    $('#inputDate1').datepicker().on('changeDate', function () {
                        $(this).datepicker('hide');
                    });
                }
                if ($('#inputDate2').size() > 0) {
                    $('#inputDate2').datepicker().on('changeDate', function () {
                        $(this).datepicker('hide');
                    });
                }
                if ($('#inputDate3').size() > 0) {
                    $('#inputDate3').datepicker().on('changeDate', function () {
                        $(this).datepicker('hide');
                    });
                }
                $(".calandar1").on("click", function () {
                    $("#inputDate1").focus();
                });
                $(".calandar3").on("click", function () {
                    $("#inputDate3").focus();
                });
                $(".calandar2").on("click", function () {
                    $("#inputDate2").focus();
                });
            },

            selfServiceChangeNemID: function (e) {
                e.preventDefault();
                window.location = $(this).data('url');
                return false;
            },

            // Click event handlers (body)
            surveyModalDismiss: function () {
                Cookies.set(surveyCookieName, 'yes', { expires: 14 });
                $('#surveyModal').modal('hide');
                $(document.body).removeClass("modal-open");
                $(".modal-backdrop").remove();
            },
            navTabsClick: function () {
                var clicked = $(this),
                    url = clicked.data('url'),
                    target = $(clicked.attr('href'));

                // Empty target tab-panel's siblings to avoid JS errors (mulitple functions sharing the same name)
                target.siblings().empty();

                // Get content for the selected tab-panel
                $.ajax({
                    type: 'POST',
                    dataType: 'html',
                    url: url + "?cache=" + new Date().getTime(),
                    cache: false,
                    success: function (data) {
                        $(target).html(data);
                    },
                    error: function (error) {
                        //alert('Fail');
                    }
                });
            },
            pleaseWaitLinkClick: function () {
                window.console.log("pleaseWaitLinkClick");
                $(pleaseWaitModalId).modal('show');
            },
            productLinkClick: function (e) {
                e.preventDefault();

                var clicked = $(this),
                    prodId = clicked.data("productId"),
                    windId = clicked.data("windId"),
                    parent = clicked.parent().parent(),
                    parentParent = parent.parent(),
                    wind = parentParent.find(".info-box__wind");

                if (wind.length) {
                    wind.show();
                    parentParent.addClass("info-box--show-wind");
                } else {
                    clicked.addClass("loading");

                    $.ajax({
                        type: 'POST',
                        dataType: 'html',
                        url: '/SelfService/RenderWindProduct?windProdId=' + windId + '&productId=' + prodId + "&cache=" + new Date().getTime(),
                        cache: false,
                        success: function (result) {
                            var newResult = $(result),
                                description = parent.prev().find(".info-box__description");

                            clicked.removeClass("loading");
                            parentParent.addClass("info-box--show-wind");
                            description.before(newResult);
                            newResult.find(".info-box__wind-close").click(function () {
                                parentParent.removeClass("info-box--show-wind");
                                newResult.hide();
                            });
                            newResult.find('form.power-product-block-wind').submit(aura.events.functions.beforeSubmitPowerProduct);
                        },
                        error: function (error) {
                            //alert('Fail');
                        }
                    });
                }
            },
            priceTrendsClick: function () {
                window.console.log("priceTrendsClick");
                $(pleaseWaitModalId).modal('show');
                var contentId = $(this).data('contentId');
                var row = $(this).closest(".grid--flex, .row--flex");

                $.ajax({
                    type: 'POST',
                    dataType: 'html',
                    url: '/ProductElBlock/PriceHistory?blockId=' + contentId,
                    cache: false,
                    success: function (result) {
                        // Remove existing price trend view (if there is one)
                        $(".priceTrendsView").remove();

                        // Get result
                        var resultDiv = $('<div />', {
                            class: "priceTrendsView"
                        }).html(result);

                        // Resolve and insert title
                        var modalTitle = resultDiv.find('input[type="hidden"][name="modalTitle"]').val().trim() || "&nbsp;";
                        if (modalTitle) {
                            resultDiv.prepend("<h2 class='pink-txt'>" + modalTitle + "</h2>");
                        }

                        // Insert close button
                        var closeBtn = $("<div />", {
                            class: "priceTrendsView__close"
                        }).one("click", function () {
                            resultDiv.remove();
                        });
                        resultDiv.prepend(closeBtn);

                        row.after(resultDiv);

                        $("html, body").animate({
                            scrollTop: resultDiv.offset().top
                        }, 1000);
                    },
                    error: function (error) {
                        window.console.log("modal: priceTrendsClick --error");
                        aura.functions.createModal("priceTrendsModal", "priceTrends", 'Prisudvikling', '<p>Der opstod en fejl</p>', null, false)
                            .modal('show');
                    },
                    complete: function () {
                        $(pleaseWaitModalId).modal('hide');
                        $(document.body).removeClass("modal-open");
                        $(".modal-backdrop").remove();
                    }
                });
            },
            selfServiceFormSubmit: function () {
                window.console.log("modal: selfServiceFormSubmit");
                //$(pleaseWaitModalId).modal('show');
            },
            
            scrollDownClick: function (e) {
                e.preventDefault();
                aura.functions.scroller($(this.hash));
            },
            learnMoreClick: function () {
                var clicked = $(this);
                clicked.siblings(".learn-more-text").slideToggle();
                clicked.toggleClass("active");
            },
            scrollToClick: function (e) {
                e.preventDefault();
                var hash = $(this).data("scroll");
                aura.functions.scroller($(hash));
            },
            tabToggleClick: function () {
                var clicked = $(this);
                clicked.parents(".tab-menu-icon").find("a.active").removeClass("active");
                clicked.toggleClass("active");
            },

            // Mouse enter/leave event handlers
            navigationLinkMouseEnter: function () {
                $(this).find(".sml-description").stop().animate({ opacity: 1 });
            },
            navigationLinkMouseLeave: function () {
                $(this).find(".sml-description").stop().animate({ opacity: 0 });
            },
            greyscaleMouseEnter: function () {
                var existing = $(this).find(".colourOns");
                existing.stop().animate({
                    opacity: 1
                }, 600);
            },
            greyscaleMouseLeave: function () {
                var existing = $(this).find(".colourOns");
                existing.stop().animate({
                    opacity: 0
                }, 800);
            },
            focusAddressSearchAndMessage: function () {
                $('.fullwidth-search__validation-message').show();
                $("#fullwidth-search__input").focus();
            },
            toggleTableAccordion: function () {
                $(this).parents('table').toggleClass('collapsed');
            },
            excavationSubscribe: function () {
                var name = $('#name--subscribe').val().replace(' ', '+');
                var email = $('#email--subscribe').val().replace(' ', '');

                $.ajax({
                    url: "/excavationWorkBlock/Notification",
                    type: 'POST',
                    data: "email=" + email + "&name=" + name,
                    success: function (data, textStatus, jqXHR) {
                        aura.events.functions.ExcavationMessage(
                            '<p>Emailadressen: <span class="text--brandcolor"> ' + email + '</span> er nu tilmeldt notifikationer på gravearbejde</p>'
                        );
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        aura.events.functions.ExcavationMessage(
                            '<p>Der skete desværre en fejl.</p>' +
                            '<p>Emailadressen: <span class="text--brandcolor">' + email + '</span> blev ikke tilmeldt notifikationer på gravearbejde</p>'
                        );
                    }
                });

                return false;
            },
            getLinkerParam: function (uaNumber) {
                var _ga = window[window.GoogleAnalyticsObject];
                var trackers = _ga.getAll();
                var i;

                for (i = 0; i < trackers.length; i++) {

                    var _tracker = trackers[i];
                    if (!uaNumber || _tracker.get('trackingId') === uaNumber) {

                        return _tracker.get('linkerParam');

                    }
                }
            },
            beforeSubmitPowerProduct: function () {
                //before submitting power product from productlist, we need to set ga tracking param in a hidden input.
                // This is later used to inject in product-order iframe url.
                var $form = $(this),
                    $gaUaNumber__input = $form.find(".ga__uaNumber"),
                    ga__uaNumber = aura.events.functions.getLinkerParam();

                $gaUaNumber__input.val(ga__uaNumber);
            },
            fiberPulje: function () {
                var $form = $(this);
                var $submitButton = $form.find('.btn-primary-spl');

                //disable submit and show loader while form is submitting
                $submitButton.addClass('loading').prop("disabled", true);

                $.ajax({
                    type: 'POST', // define the type of HTTP verb we want to use (POST for our form)
                    url: $form.attr('action'), // the url where we want to POST
                    data: $form.serialize(), // our data object
                    dataType: 'json', // what type of data do we expect back from the server
                    encode: true
                })
                    // using the done promise callback
                    .done(function (data) {
                        var $response = '<div><h3>' + data.title + '</h3>' + data.message + '</div>';

                        $form.parent().html($response);

                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".contentarea__body").offset().top
                        }, 300);
                    })
                    .fail(function (jqXHR, textStatus) {
                        window.console.log(textStatus);
                        $submitButton.removeClass('loading').prop("disabled", false);
                        $('.error-message').text('Der skete en fejl - prøv igen');
                    });

                // stop the form from submitting the normal way and refreshing the page
                return false;
            },
            initFiberFlow: function () {
                var $companyPaidCheck = $('#CompanyPaidFiber'),
                    $companyInputContainer = $('#js-company-payment-container'),
                    $companyInputs = $companyInputContainer.find('input'),
                    $companyInputEmail = $companyInputContainer.find('input.company-email'),
                    $companyInputAddress = $companyInputContainer.find('input.js-dawa-autocomplete'),
                    $companyInputCVR = $companyInputContainer.find('input.company-cvr'),
                    $companyPayment = $companyInputContainer.find('.company-payment'),
                    $newsSignupInputs = $('.toggle-required-newstoc'),
                    $permissionNewsCheck = $('#PermissionNewsCheck'),
                    $wifiQuizSectionToggles = $('.js-wifi-quiz-section-toggle');

                var $movingDate = $('#MovingDate');
                var $subscriptionStartDate = $('#SubscriptionStartDate');

                var $currentAddressContainer = $('#js-current-address-container'),
                    $currentAddressContainerInputs = $currentAddressContainer.find('input');

                var $newAddressContainer = $('#js-new-address-container'),
                    $newAddressContainerInputs = $newAddressContainer.find('input');

                var $currentAddressNoteBlock = $currentAddressContainer.find('.js-address-note');
                var $currentAddressCheckbox = $currentAddressNoteBlock.find('#acceptWithdrawalExpiration');
                                    
            // Init flatpicker
            $($movingDate).flatpickr({
                "locale": "da",  // locale for this instance only
                dateFormat: 'd/m/Y',
                minDate: "today",
                disableMobile: "true"
            });

            var datepicker = $($subscriptionStartDate).flatpickr({
                "locale": "da",  // locale for this instance only
                dateFormat: 'd/m/Y',
                minDate: "today",
                disableMobile: "true"
            });

                $subscriptionStartDate.on('change', function () {
                    var delta = 14; //2 weeks
                    var currentDate = datepicker.now;
                    var selectedDate = datepicker.selectedDates[0];
                    var difference = Math.ceil((selectedDate - currentDate) / (1000 * 60 * 60 * 24));

                    if (difference <= delta) {
                        $currentAddressCheckbox.addClass('required-check error');
                        $currentAddressCheckbox.prop('required', true);

                        $currentAddressNoteBlock.fadeIn(500);
                    } else {
                        $currentAddressCheckbox.removeClass('required-check error');
                        $currentAddressCheckbox.prop('required', false);
                        $currentAddressCheckbox.prop('checked', false);

                        $currentAddressNoteBlock.fadeOut([500], [function () {
                            $currentAddressNoteBlock.hide();
                        }]);
                    }
                });

            // Show / hide payment container
            $($companyPaidCheck).change(function() {
                if($($companyPaidCheck).is(':checked')) {
                    $companyInputContainer.fadeIn(500);
                } else {
                    $companyInputContainer.hide();
                }
            });

            if($wifiQuizSectionToggles){
                $($wifiQuizSectionToggles).change(function(){
                    setWifiQuizSectionVisibility();
                });
            }

            // check clected product radiobutton
            var radioButtons = $("input:radio[name='Product'], input:radio[name='TvProduct']");
            var activeClass = "c-selected-product-grid__item--active";

            radioIsActive();
            $("input:radio[name='Product']").on("click", radioIsActive);
            $("input:radio[name='TvProduct']").on("click", radioIsActive);
            function radioIsActive() {
                radioButtons.each(function () {
                    if ($(this).prop("checked")) {
                        $(this).closest(".c-selected-product-grid__item").addClass(activeClass);

                    } else {
                        $(this).closest(".c-selected-product-grid__item").removeClass(activeClass);
                    }
                });
            }

            //Product and Wi-fi radio groups dependencies in the fiber form
            processFiberProductSelection();
            $("input:radio[name='Product']").on("change", function () {

                handleHousingTypeSelection();
                processFiberProductSelection();

            });

            processTvProductSelection();
            $("input:radio[name='TvProduct']").on("change", function () {
                processTvProductSelection();
            });

            $("input:radio[name='WifiHousingType']").on("change", function () {
                handleHousingTypeSelection();
            });

            function handleHousingTypeSelection() {
                var selectedProductId = $("input:radio[name='Product']:checked").attr("id");
                var $selectHousingTypeOption = $("input:radio[name='WifiHousingType']:checked");

                var productsToBeUpscaledIds = $selectHousingTypeOption.data("product-to-be-upscaled-ids") || [];
                var productsRequiredExtraWifiUnitIds = $selectHousingTypeOption.data("product-required-extra-wifi-unit-ids") || [];

                if (productsToBeUpscaledIds.includes(selectedProductId)) {
                    $("#js-product-to-be-upscaled-text-container").show(0);
                    $("#js-order-extra-wifi-unit-checkbox-container").hide();
                } else if (productsRequiredExtraWifiUnitIds.includes(selectedProductId)) {
                    $("#js-product-to-be-upscaled-text-container").hide();
                    $("#js-order-extra-wifi-unit-checkbox-container").show();
                } else {
                    $("#js-product-to-be-upscaled-text-container").hide();
                    $("#js-order-extra-wifi-unit-checkbox-container").hide();
                }
            }

            function processFiberProductSelection() {

                setWifiQuizSectionVisibility();

                var isAuraSelectedProduct = $("input:radio[name='Product']:checked").data("is-aura-product");
                var productFiberBlockId = $("input:radio[name='Product']:checked").data("product-fiber-block-id");
                var fullFormBlocks = $(".js-full-form-block");
                var simplifiedFormBlocks = $(".js-simplified-form-block");

                var generalAcceptTermsCheck = $("#generalAcceptTermsCheck");
                var auraAcceptTermsCheck = $("#auraAcceptTermsCheck");

                $("#IsAuraProduct").val(isAuraSelectedProduct);
                $("#ProductFiberBlockId").val(productFiberBlockId);
                
                if (!isAuraSelectedProduct) {
                    fullFormBlocks.each(function () {
                        $(this).show();
                    });
                    simplifiedFormBlocks.each(function () {
                        $(this).hide();
                    });

                    //toggle required status for acceptTerms checkboxes
                    generalAcceptTermsCheck.addClass('required-check');
                    generalAcceptTermsCheck.prop('required', true);

                    auraAcceptTermsCheck.removeClass('required-check');
                    auraAcceptTermsCheck.prop('required', false);

                } else {
                    fullFormBlocks.each(function () {
                        $(this).hide();
                    });
                    simplifiedFormBlocks.each(function () {
                        $(this).show();
                    });

                    //toggle required status for acceptTerms checkboxes
                    generalAcceptTermsCheck.removeClass('required-check');
                    generalAcceptTermsCheck.prop('required', false);

                    auraAcceptTermsCheck.addClass('required-check');
                    auraAcceptTermsCheck.prop('required', true);
                }
            }

            function processTvProductSelection() {
                var tvProductBlockId = $("input:radio[name='TvProduct']:checked").data("tv-product-block-id");
                $("#TvProductBlockId").val(tvProductBlockId);
            }

            function setWifiQuizSectionVisibility() {

                var isAuraSelectedProduct = $("input:radio[name='Product']:checked").data("is-aura-product");
                var showWifiQuiz = $(".js-wifi-quiz-section-toggle:checked").length > 0;

                var $wifiQuizSection = $(".js-wifi-quiz-section");

                $("#HasWifiQuizOnOrder").val(showWifiQuiz);

                if(showWifiQuiz) {
                    $wifiQuizSection.fadeIn(500);

                    enableWifiQuizAnswers();

                }
                else {
                    $wifiQuizSection.fadeOut(500);
                    disableWifiQuizAnswers();
                }
            }

            function enableWifiQuizAnswers() {
                var $wifiQuizAnswerBlocks = $(".js_wifi_quiz_answer");
                    $wifiQuizAnswerBlocks.each(function () {
                        $(this).addClass('required-select');
                        $(this).prop('required', true);
                    });
            }

            function disableWifiQuizAnswers(){
                var $wifiQuizAnswerBlocks = $(".js_wifi_quiz_answer");
                $wifiQuizAnswerBlocks.each(function () {
                    $(this).removeClass('required-select');
                    $(this).prop('required', false);
                });
            }

            // Check if user resident on address, add validation accordingly
            $('input:radio[name="IsNotLivingByAddress"]').change(function(e) {
                
                if (e.currentTarget.value == 'true') {
                    $newAddressContainer.fadeIn(500);
                    $newAddressContainerInputs.each(function () {
                        addClassToInput($(this));
                    });

                    $currentAddressContainer.hide();
                    $currentAddressContainerInputs.each(function () {
                        clearInput($(this));
                    });

                    $currentAddressCheckbox.removeClass('required-check error');
                    $currentAddressCheckbox.prop('required', false);
                    $currentAddressCheckbox.prop('checked', false);
                    $currentAddressNoteBlock.fadeOut([500], [function () {
                        $currentAddressNoteBlock.hide();
                    }]);

                } else {
                    $currentAddressContainer.fadeIn(500);
                    $currentAddressContainerInputs.each(function () {
                        addClassToInput($(this));
                    });

                    $newAddressContainer.hide();
                    $newAddressContainerInputs.each(function () {
                        clearInput($(this));
                    });
                }

                // Remove validation from inputs, and clear form elements
                function clearInput(input) {
                    input.val('');
                    if (input.is($("#MovingDate")) || input.is($("#SubscriptionStartDate"))) {
                        input.removeClass('required error input-validation-error');
                    } else if(!input.is('checkbox')) {
                        input.removeClass('required-text error');
                    }
                }
                // Add validation for inputs
                function addClassToInput(input) {
                    if (input.is($("#MovingDate")) || input.is($("#SubscriptionStartDate"))) {
                        input.addClass('required error input-validation-error');
                    } else if (!input.is(':checkbox')) {
                        input.addClass('required-text error');
                    }
                }
            });

            //if user has signed up for any type of news, the news toc becomes mandatory
            $newsSignupInputs.on('change', function () {
                //start by resetting validation errors if any
                $permissionNewsCheck.removeClass('input-validation-error error');
                $permissionNewsCheck.parent().removeClass('error');

                if ($newsSignupInputs.is(":checked")) {
                    $permissionNewsCheck.addClass('required-check');
                    $permissionNewsCheck.prop('required', true);
                } else {
                    $permissionNewsCheck.removeClass('required-check');
                    $permissionNewsCheck.prop('required', false);
                }
            });

            //if user has accepted news, the news inputs (phone and email) becomes mandatory
            $permissionNewsCheck.on('change', function () {
                //start by resetting validation errors if any
                $newsSignupInputs.removeClass('input-validation-error error');
                $newsSignupInputs.closest('.check-group').removeClass('error');

                if ($permissionNewsCheck.is(":checked")) {
                    $newsSignupInputs.addClass('required-check-in-group');
                } else {
                    $newsSignupInputs.removeClass('required-check-in-group');
                }
            });

            //if company payment is chosen, it toggles a bunch og company inputs to required
            $companyPaidCheck.on('change', function () {
            if ($companyPaidCheck.is(':checked')) {
                $companyInputs.each(function () {
                    var $this = $(this);

                    if ($this.is($companyInputEmail)) {
                        $companyInputEmail.addClass('required-email');
                    } else if ($this.is($companyInputCVR)) {
                        //company cvr is not required
                    } else if ($this.is($companyInputAddress)) {
                        $companyInputAddress.addClass('required-dawa-address');
                        $companyInputAddress.prop('required', true);
                    } else if ($companyPayment.has($this).length) {
                        $this.addClass('required-check-in-group');
                    } else {
                        $this.addClass('required-text');
                    }
                });

            } else {
                $companyInputs.each(function () {
                    var $this = $(this);

                    if ($this.is($companyInputEmail)) {
                        $companyInputEmail.removeClass('required-email');
                    } else if ($this.is($companyInputCVR)) {
                        //company cvr is not required
                    } else if ($this.is($companyInputAddress)) {
                        $companyInputAddress.removeClass('required-dawa-address');
                        $companyInputAddress.prop('required', false);
                    } else if ($companyPayment.has($this).length) {
                        $this.removeClass('required-check-in-group');
                    } else {
                        $this.removeClass('required-text');
                    }
                });

            }
        });
            },
            gdprRequest: function () {
                var $submitButton = $(this).find('.btn-primary-spl');

                //disable submit and show loader while form is submitting
                $submitButton.addClass('loading').prop("disabled", true);

                $.ajax({
                    type: 'POST', // define the type of HTTP verb we want to use (POST for our form)
                    url: $(this).attr('action'), // the url where we want to POST
                    data: $(this).serialize(), // our data object
                    dataType: 'html', // what type of data do we expect back from the server
                    encode: true
                })
                    // using the done promise callback
                    .done(function (data) {
                        $('.pb80').html(data);
                        $(window).resize().scrollTop(0);
                    })
                    .fail(function (jqXHR, textStatus) {
                        window.console.log(textStatus);
                        $submitButton.removeClass('loading').prop("disabled", false);
                        $('.error-message').text('Der skete en fejl - prøv igen');
                    });

                // stop the form from submitting the normal way and refreshing the page
                return false;
            },
            showGdprModal: function () {
                window.console.log("show GDPR modal with timeout");
                setTimeout(function () {
                    $('#modal--gdpr').modal('show');
                }, 100);
            },
            gdprAccept: function (e) {
                e.preventDefault();
                document.cookie = "newUser=false;path=/";
                $('#modal--gdpr').modal('hide');
                $(document.body).removeClass("modal-open");
                $(".modal-backdrop").remove();
            },
            operationalInfoChange: function (e) {
                var allCheckboxes = $('.slider-toggle__input');
                var $this = $(e.currentTarget);
                var hiddenInput = $('#operational-info-id');
                var checkedState = $this.prop('checked');

                if (checkedState) {
                    allCheckboxes.not($this).each(function () {
                        if ($(this).prop("checked")) {
                            $(this).data('cancel', 'Aktiv');
                        }

                        $(this).prop("checked", false);
                        $(this).next().text("Passiv");
                    });

                    $this.data('cancel', 'Passiv');
                    $this.next().text("Aktiv");
                    hiddenInput.val($this.data('id'));
                } else {
                    $this.data('cancel', 'Aktiv');
                    $this.next().text("Passiv");
                    hiddenInput.val('');
                }

                setTimeout(function () {
                    $('#operationalInfo').modal();
                }, 500);
            },
            operationalInfoCancel: function () {
                var allCheckboxes = $('.slider-toggle__input');
                allCheckboxes.each(function () {
                    if ($(this).data("cancel") === 'Aktiv') {
                        $(this).prop("checked", true);
                        $(this).next().text("Aktiv");
                    } else {
                        $(this).prop("checked", false);
                        $(this).next().text("Passiv");
                    }
                    $(this).removeData("cancel");
                });
            },
            excavationUnSubscribe: function () {
                var email = $('#email--unsubscribe').val();

                $.ajax({
                    url: "/excavationWorkBlock/Notification",
                    type: 'DELETE',
                    data: "email=" + email,
                    success: function (data, textStatus, jqXHR) {
                        aura.events.functions.ExcavationMessage(
                            '<p>Emailadressen: <span class="text--brandcolor"> ' + email + '</span> er nu afmeldt notifikationer på gravearbejde</p>'
                        );
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        aura.events.functions.ExcavationMessage(
                            '<p>Der skete desværre en fejl.</p>' +
                            '<p>Emailadressen: <span class="text--brandcolor">' + email + '</span> blev ikke afmeldt notifikationer på gravearbejde</p>'
                        );
                    }
                });

                return false;
            },

            ExcavationMessage: function (message) {
                $("#excavation-notification--toggle").removeAttr('checked');
                $('[data-excavation-notification__message]').html(message);
            },

            //if excavationId exist in window.location we want to expand this table element and scroll window to its position
            ExcavationDeeplink: function () {
                if (aura.events.functions.getUrlParameter('excavationId')) {
                    var excavationId = aura.events.functions.getUrlParameter('excavationId');
                    var currentExcavation = $("#" + excavationId);

                    if (currentExcavation.length > 0) {
                        currentExcavation.removeClass('collapsed');

                        $('html,body').animate({
                            scrollTop: currentExcavation.offset().top
                        }, 500);
                    }
                }
            },

            getUrlParameter: function getUrlParameter(sParam) {
                var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                    sURLVariables = sPageURL.split('&'),
                    sParameterName,
                    i;

                for (i = 0; i < sURLVariables.length; i++) {
                    sParameterName = sURLVariables[i].split('=');

                    if (sParameterName[0] === sParam) {
                        return sParameterName[1] === undefined ? true : sParameterName[1];
                    }
                }
            },
            togglePermissionCheck: function () {
                if (aura.DOM.document.permissionCheck.is(":checked")) {
                    aura.DOM.document.permissionSubmitButton.attr('disabled', false);
                } else {
                    aura.DOM.document.permissionSubmitButton.attr('disabled', true);
                }
            },
            togglePhoneRequired: function () {
                if (aura.DOM.phoneCheckboxe.is(":checked")) {
                    aura.DOM.permissionPhoneField.prop('required', true);
                    aura.DOM.permissionPhoneField.addClass('required-number');
                } else {
                    aura.DOM.permissionPhoneField.prop('required', false);
                    aura.DOM.permissionPhoneField.removeClass('required-number');
                }
            },
            setupPermissionEvents: function () {
                aura.DOM.document.permissionSubmitButton = $('#permissionSubmit');
                aura.DOM.document.permissionCheck = $('#permissionTxtCheck');

                aura.DOM.document.permissionSubmitButton.attr('disabled', true);
                aura.DOM.document.permissionCheck.on('change', aura.events.functions.togglePermissionCheck);

                aura.DOM.permissionPhoneField = $("#phone");
                aura.DOM.phoneCheckboxe = $('.toggle-required-phone');

                aura.DOM.phoneCheckboxe.on('change', aura.events.functions.togglePhoneRequired);

            },
            infoModal: function () {
                window.console.log("modal: show infoModal");
                $('#' + this.dataset.modal).modal('show');
            },
            removeStaticHeight: function (element) {
                if ($(".info-box-horizontal input:checked").length > 0) {
                    element.target.closest(".match-height").style.height = "auto";
                } else {
                    aura.functions.setBlockHeights('.info-box-horizontal .match-height', "input:checked", true);
                }
            },
            anchorScroll: function (e) {
                var decodedHash = decodeURIComponent(this.hash);
                var anchorElement = $(decodedHash);
                if (anchorElement.length) {
                    aura.functions.scroller($(this.hash));
                }
            }
        }
    };


    /**
    * Validation
    */
    aura.validation = {
        init: function () {
            var errorClass = 'error';
            var successClass = 'success';
            var hiddenClass = 'u-visuallyhidden';
            var regEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            var regText = /[^\s]/; // All but white-space (line breaks, tabs, spaces, hard spaces)
            var regTextArea = /^(?!\s*$).+/;
            var regPhone = /^[0-9]+$/;
            var regCustomerNumber = /^(0|[1-9][0-9]*)$/;
            var regCustomerPassword = /^[a-z0-9]+$/;

            var successFlag = true;
            var scrollFlag = true;

            jQuery('.validate-form').each(function () {
                var form = jQuery(this);
                var messages = {
                    "message1": "Du mangler at udfylde ovenstående markerede felter"
                };

                form.attr('novalidate', 'novalidate');
                successFlag = true;
                var inputs = form.find('input:text, input[type="email"], textarea, select, input[type="checkbox"],input[type="radio"] ');
                var message = 'There is an error';
                var permissionSubmitButton = document.getElementById('permissionSubmit');
                var permissionSubmitLoader = document.getElementById('permissionSubmitLoader');

                // form validation function
                function validateForm() {
                    successFlag = true;
                    scrollFlag = true;

                    inputs.each(checkField);

                    if (successFlag) {

                        /* Logic for adding loader and disable submit button */

                        // Fiber Flow Form
                        if (permissionSubmitButton) {
                            permissionSubmitButton.disabled = true;

                            if (permissionSubmitLoader) {
                                permissionSubmitLoader.classList.remove(hiddenClass);
                            }
                        }

                        // SelfServiceRemoveAccountForm
                        if (selfServiceSubmitButton) {
                            selfServiceSubmitButton.disabled = true;

                            if (selfServiceSubmitLoader) {
                                selfServiceSubmitLoader.classList.remove(hiddenClass);
                            }
                        }
                    }
                    if (!successFlag) {
                        return false;
                    }

                    form.find(".error-message").hide();
                }

                // check field
                function checkField(i, obj) {
                    var currentObject = jQuery(obj);
                    var currentParent = currentObject.parents('div.valid-row');

                    // not empty fields
                    if (currentObject.hasClass('required')) {
                        // message = messages[currentObject.data("msg")];
                        message = messages.message1;
                        setState(message, currentParent, currentObject, !currentObject.val().length || currentObject.val() === currentObject.prop('defaultValue'));
                    }
                    // not empty fields
                    if (currentObject.hasClass('required-text')) {
                        message = messages.message1;
                        if (typeof (message) === 'undefined') {
                            message = currentObject.data("msg-text");
                        }
                        if (currentObject[0] != 'undefined' && currentObject[0].type == 'textarea') {
                            setState(message, currentParent, currentObject, !currentObject.val().length || currentObject.val() === currentObject.prop('defaultValue') || !regTextArea.test(currentObject.val()));
                        } else {
                            setState(message, currentParent, currentObject, !currentObject.val().length || currentObject.val() === currentObject.prop('defaultValue') || !regText.test(currentObject.val()));
                        }

                    }

                    // correct address fields
                    if (currentObject.hasClass('required-dawa-address-id')) {

                        message = currentObject.data("msg-text");
                        if (typeof (message) === 'undefined') {
                            message = "Adressen er ikke komplet";
                        }
                        setState(message, currentParent, currentObject, $("#dawaAddressId").val() === emptyUUID);
                    }

                    // correct required-dawa-address field
                    if (currentObject.hasClass('required-dawa-address')) {

                        message = messages.message1;
                        if (typeof (message) === 'undefined') {
                            message = currentObject.data("msg-text");
                        }
                        setState(message, currentParent, currentObject, $("#dawaAddress").val() === '');
                    }

                    // correct email fields
                    if (currentObject.hasClass('required-email')) {
                        message = currentObject.data("msg-text");
                        if (typeof (message) === 'undefined') {
                            message = messages.message1;
                        }
                        setState(message, currentParent, currentObject, !regEmail.test(currentObject.val()));
                    }
                    // correct number fields
                    if (currentObject.hasClass('required-number')) {

                        message = currentObject.data("msg-text");
                        if (typeof (message) === 'undefined') {
                            message = messages.message1;
                        }
                        setState(message, currentParent, currentObject, !regPhone.test(currentObject.val()));
                    }
                    // correct number fields
                    if (currentObject.hasClass('required-acc')) {
                        message = messages.message1;
                        if (typeof (message) === 'undefined') {
                            message = currentObject.data("msg-text");
                        }
                        setState(message, currentParent, currentObject, !regPhone.test(currentObject.val()) || currentObject.val().length < 5);
                    }

                    // something selected
                    if (currentObject.hasClass('required-select')) {
                        message = messages.message1;
                        if (typeof (message) === 'undefined') {
                            message = currentObject.data("msg-text");
                        }
                        setState(message, currentParent, currentObject, currentObject.get(0).selectedIndex === 0);
                    }
                    // required check
                    if (currentObject.hasClass('required-check')) {
                        message = messages.message1;
                        if (typeof (message) === 'undefined') {
                            message = currentObject.data("msg-text");
                        }
                        // check if at least one selected
                        var check = currentObject.prop('checked');
                        setState(message, currentParent, currentObject, check < 1);
                    }
                    // Checkbox group
                    if (currentObject.hasClass('required-check-in-group')) {
                        message = messages.message1;
                        if (typeof (message) === 'undefined') {
                            message = currentObject.data("msg-text");
                        }
                        // check if at least one selected
                        var check2 = currentObject.parents(".check-group").find("input:checked").size();

                        setState(message, currentParent, currentObject, check2 === 0);
                    }

                }

                // set state
                function setState(msg, hold, field, error) {
                    field.removeClass("error");
                    field.siblings().removeClass("error");
                    field.parent().removeClass("error");
                    field.parents('.check-group').removeClass("error");

                    if (error) {
                        field.addClass("error");
                        if (field.hasClass("required-select")) {
                            field.siblings("span").addClass("error");
                        } else if (field.hasClass("required-check-in-group")) {
                            hold.addClass("error");
                        } else if (field.hasClass('required-check')) {
                            field.parent().addClass('required-check--label error');
                        }
                        showError(msg);
                        successFlag = false;

                        if (scrollFlag) {
                            if (field.hasClass("required-check-in-group")) {
                                var parent = field.context.closest(".js-fiber-products");

                                if (parent) {
                                    parent.scrollIntoView({ behavior: "smooth" });
                                } else {
                                    field.context.closest(".check-group").scrollIntoView({ behavior: "smooth" });
                                }
                            } else if (field.hasClass("required-check")) {
                                field.context.closest(".c-checkbox").scrollIntoView({ behavior: "smooth" });
                            } else {
                                field.context.scrollIntoView({ behavior: "smooth" });
                            }
                            scrollFlag = false;
                        }
                        
                    } else {
                    }
                }

                function showError(m) {
                    var er = form.find(".error-message");
                    if (!er.is(':visible')) {
                        er.show().text(m);
                    } else {
                        er.text(m);
                    }
                }

                // form event handlers
                form.submit(validateForm);
            });

            /* Specific rules and loading state for forms */

            // Specific patterns for pairing form
            if ($("#customerNo").length) {
                $("#customerNo").rules("add", { regex: regCustomerNumber });
            }
            if ($("#customerPassword").length) {
                $("#customerPassword").rules("add", { regex: regCustomerPassword });
            }

            var selfServiceSubmitButton = document.getElementById('selfServiceFormSubmitBtn');
            var selfServiceSubmitLoader = document.getElementById('selfServiceFormSubmitBtnLoader');

            $("#pairing-form").submit(function () {
                if($(this).valid()) {
                    if (selfServiceSubmitButton) {
                        selfServiceSubmitButton.disabled = true;

                        if (selfServiceSubmitLoader) {
                            selfServiceSubmitLoader.classList.remove(hiddenClass);
                        }
                    }
                }
            });


            // Specific patterns for edit profile form
            if ($("#profileEmail").length) {
                $("#profileEmail").rules("add", { regex: regEmail });
            }
            if ($("#profilePhoneNumber").length) {
                $("#profilePhoneNumber").rules("add", { regex: regPhone });
            }

            $("#edit-my-profile-form").submit(function () {
                if($(this).valid()) {
                    $(this).addClass('edit-profile-form-is-valid');
                }
                else {
                    $(this).removeClass('edit-profile-form-is-valid');
                }
            });

            // Specific patterns for my login info form
            if ($("#loginInfoEmail").length) {
                $("#loginInfoEmail").rules("add", { regex: regEmail });
            }

            $("#my-login-info-form").submit(function () {
                if ($(this).valid()) {
                    $(this).addClass('my-login-info-form-is-valid');
                }
                else {
                    $(this).removeClass('my-login-info-form-is-valid');
                }
            });


            /* Freezing arrangement form start */
            if ($("#customerEmail").length) {
                $("#customerEmail").rules("add", { regex: regEmail });
            }
            /* Freezing arrangement form start */
            if ($("#customerEmailAlt").length) {
                $("#customerEmailAlt").rules("add", { regex: regEmail });
            }

            var $freezingAgreementSubmitButton = document.getElementById('freezingAgreementSubmitButton');
            var $freezingAgreementLoader = document.getElementById('freezingAgreementLoader');
            // Validate form using jQuery validate
            $("#freezing-agreement-form").submit(function () {
                if($(this).valid()) {
                    $(this).addClass('freezing-agreement-form-is-valid');

                    if ($freezingAgreementSubmitButton) {
                        $freezingAgreementSubmitButton.disabled = true;

                        if ($freezingAgreementLoader) {
                            $freezingAgreementLoader.classList.remove(hiddenClass);
                        }
                    }
                }
                else {
                    $(this).removeClass('freezing-agreement-form-is-valid');
                }
            });
        }
    };


    aura.functions.preloadImages([
        '/images/menu_hover_1.png',
        '/images/menu_hover_2.png',
        '/images/menu_hover_3.png',
        '/images/menu_hover_4.png',
        '/images/menu_hover_5.png',
        '/images/icon-1-hover.png'
    ]);


    aura.DOM.document.ready(function () {
        aura.init();
    });

    aura.DOM.window.load(function () {
        aura.delayedInit();
    });

}(window.jQuery, _, (window.AURA = window.AURA || {}), window, document));
