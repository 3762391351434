/*global jQuery, _ */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: ...
 Date of creation: ...
 */

var dis = dis || {};

(function ($, dis, underscore) {
    "use strict";

    /**
     * The base module we'll expand on.
     *
     * @class
     * @constructor
     */
    dis.BaseModule = function () {
    };


    /**
     * This will be SHARED across all instances, and populated in the IIFE that sets up
     * onReady(), a bit further down.
     *
     * @type {object}
     */
    dis.BaseModule.prototype.globalDOM = {};


    /**
     * This IIFE returns a function which performs actions once jQuery is prepared and ready.
     * You can use the resulting function like this:
     *
     *     this.onReady(function() {});
     *
     * ... which will fire your function once the DOM is ready and jQuery is prepared.
     *
     * @type {function}
     */
    dis.BaseModule.prototype.onReady = (function () {

        // First, set up a temporary queue to hold our various callback functions.
        var functionQueue = [],
            // Also, we'll need an internal indication of whether or not jQuery is ready.
            domIsReady = false,
            returnFunction;

        // Once jQuery is ready, do this.
        $(function () {
            // Store references to body and window in variables that are globally available. This means we'll
            // only need to target them ONCE per page load.
            dis.BaseModule.prototype.globalDOM.html = $("html");
            dis.BaseModule.prototype.globalDOM.body = $("body");
            dis.BaseModule.prototype.globalDOM.window = $(window);

            domIsReady = true;

            // Fire any and all functions that are queued up to this point.
            underscore.each(functionQueue, function (funcRef) {
                funcRef();
            });
        });

        /**
         * Store a function reference that will be fired as soon as the DOM and jQuery is ready.
         *
         * @param {function} functionReference
         */
        returnFunction = function (functionReference) {
            if (typeof functionReference === "function") {
                if (!domIsReady) {
                    functionQueue.push(functionReference);
                } else {
                    functionReference();
                }
            }
        };

        // Return a function that can store a function reference and queue it until the DOM is ready to fire.
        return returnFunction;
    }());

}(jQuery, dis, _));